<template>
    <v-hover v-slot="{ isHovering: isHoveringRoot, props: rootHoverProps }">
        <div
            class="k-avatar"
            :class="kAvatarClasses"
            v-bind="{ ...rootHoverProps, ...$attrs }"
            ref="prospus_avatar"
            @click.stop="onClick"
        >
            <v-avatar
                :color="props.bgColor"
                :size="avatarSize"
                :rounded="rounded"
                :style="{
                    opacity: loading ? 0.5 : 1,
                }"
            >
                <Image :src="imageSrc"/>
                
                <span>{{ firstLetter }}</span>
            </v-avatar>

            <div v-if="showShiftPaused" class="k-avatar-shift">
                <span class="material-symbols-outlined">pause</span>
            </div>

            <div
                v-if="showClose"
                class="k-avatar-close"
                :class="{ 'k-avatar-close-show': isHoveringRoot }"
                @click="$emit('close')"
            >
                <v-icon size="x-small" color="white" icon="mdi-close" />
            </div>

            <v-hover
                v-slot="{ isHovering: isHoveringCheck, props: checkHoverProps }"
            >
                <div
                    v-if="showAssignCheck"
                    class="k-avatar-assign-check"
                    v-bind="checkHoverProps"
                    @click="$emit('assignCheckClick')"
                >
                    <v-icon
                        size="x-small"
                        color="white"
                        :icon="isHoveringCheck ? 'mdi-close' : 'mdi-check'"
                    />
                </div>
            </v-hover>

            <div v-if="showShield" class="k-avatar-shield">
                <span class="material-symbols-outlined">shield_person</span>
            </div>

            <div v-if="showTaskStatus" class="k-avatar-task-status"></div>

            <div
                v-if="loading"
                class="position-absolute top-0 w-100 h-100 d-flex align-center justify-center"
            >
                <v-progress-circular
                    :size="15"
                    :width="2"
                    color="primary"
                    indeterminate
                />
            </div>
        </div>
    </v-hover>
</template>

<script>
export const SIZE_MAP = {
    "x-small": 15,
    "m-small": 20,
    small: 25,
    medium: 30,
    default: 40,
    large: 50,
    "x-large": 80,
    "xx-large": 100,
};

export const TASK_STATUS = ["active", "paused", "blocked"];
</script>

<script setup>
import { 
    ref,
    computed, 
} from "vue";
import Image from "../../../Components/Common/Image.vue";

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    imageSrc: String,
    size: {
        type: String,
        default: "default",
        validator: (value) => Object.keys(SIZE_MAP).includes(value),
    },
    bgColor: {
        type: String,
        default: "grey-lighten-1",
    },
    showTaskStatus: Boolean,
    taskStatus: {
        type: String,
        default: "active",
        validator: (value) => TASK_STATUS.includes(value),
    },
    showShield: Boolean,
    showShiftPaused: Boolean,
    showClose: Boolean,
    showAssignCheck: Boolean,
    rounded: String,
    loading: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(["assignCheckClick", "close"]);

const prospus_avatar = ref(null)

const avatarSize = computed(() => SIZE_MAP[props.size] + "px");
const firstLetter = computed(() => props.name?.charAt(0).toUpperCase());

const kAvatarClasses = computed(() => ({
    [`k-avatar-task-status--${props.taskStatus}`]: props.showTaskStatus,
}));

function onClick(e) {
    e.preventDefault();
}
</script>

<style lang="scss">
$size: v-bind(avatarSize);

.k-avatar {
    position: relative;
    width: $size;
    height: $size;
    display: inline-block;

    .v-avatar {
        position: relative;

        .v-img {
            position: absolute;
            width: $size;
            height: $size;
            z-index: 1;
        }
        span {
            position: absolute;
            width: $size;
            height: $size;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: calc($size * 0.5);
        }
    }

    > div:not(.v-avatar) {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc($size * 0.5);
        height: calc($size * 0.5);
        border-radius: 50%;
        border: 1px solid white;
    }

    .k-avatar-close {
        top: 0%;
        right: 0%;
        transform: translate(20%, -25%);
        background: #b01216;
        cursor: pointer;
        opacity: 0;
        transition: opacity 300ms;

        .v-icon {
            font-size: calc($size * 0.3);
        }

        &.k-avatar-close-show {
            opacity: 1;
        }
    }

    .k-avatar-task-status {
        top: 50%;
        right: 0%;
        transform: translate(50%, -50%);
        background: #6fc66f;
        border-width: 2px !important;
    }

    &.k-avatar-task-status--paused {
        .k-avatar-task-status {
            background: gray;
        }
    }

    &.k-avatar-task-status--blocked {
        .k-avatar-task-status {
            background: #b01216;
        }
    }

    .k-avatar-shield {
        bottom: 0%;
        right: 0%;
        transform: translate(20%, 25%);
        background: green;
        padding-top: 1px;

        span {
            color: white;
            font-size: calc($size * 0.375);
        }
    }

    .k-avatar-shift {
        top: 50%;
        left: 0%;
        transform: translate(-50%, -50%);
        background: white;

        span {
            color: #36454f;
            font-size: calc($size * 0.375);
        }
    }

    .k-avatar-assign-check {
        bottom: 0%;
        left: 0%;
        transform: translate(-25%, 25%);
        background: green;
        cursor: pointer;
        transition: background 300ms;

        .v-icon {
            font-size: calc($size * 0.3);
        }

        &:hover {
            background: #b01216;
        }
    }
}
</style>
